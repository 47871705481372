import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  Span,
  OptinModal,
  Button
} from "@/components"

export default function OptIn({data}) {
  const [modalOpened, setModalOpened] = useState(false)
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light" squeeze={true}>
      <SEO
        title="Foundations of Accelerated Learning"
        description="A Free Course from Sonora Guitar Intensive."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box
            width={["100%", null, null, 5 / 12]}
            textAlign="center"
            mx="auto"
          >
          <Text variant="subhead" mt="4rem">FREE <strong>8-Day</strong> Course</Text>

          <Heading level="1" mb="1rem" mt="1rem" px={["2rem","2rem","2rem","2rem"]}>
            Foundations of Accelerated Learning
          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem","2rem","2rem","2rem"]}>
            A guide to superchanging the learning process.
          </Text>
        </Box>
        <Width
          display="flex"
          flexDirection={["column-reverse", "row"]}
          pt={["1.2rem", null, null, "4.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 6 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            pr={["0", "1rem"]}
          >
            <Box
            display="flex"
            flexDirection={["column-reverse", "column"]}
            >
              <Box>
                <Text mb="2.3rem">This is the first module from our Core Program and this week we're giving it away for free.</Text>
                <Text mb="2.3rem">
                  In this {" "}
                  <Span fontWeight="600" color="amethyst">
                    FREE course
                  </Span>{" "}
                  you'll learn:
                </Text>
                <List bullets="checkmark">
                  <ListItem>How to deconstruct any skill into consumable chunks and find a clear mastery path within in.</ListItem>
                  <ListItem>What a guitar mastery path looks like.</ListItem>
                  <ListItem>The 3 types of practice and how to stop wasting time in the practice room.</ListItem>
                  <ListItem>Techniques for absorbing, polishing and retaining techniques on guitar.</ListItem>
                </List>
              </Box>
              <Box mb={["2rem", null, null, "0"]}>
                <Button variant="special" onClick={() => setModalOpened(true)} mb="1.2rem" mt="5rem">
                  Get the Course 
                </Button>
              </Box>
            </Box>
          </Box>
          <Box width={["100%", 6 / 12]} textAlign="left" ml={["0", "auto"]} pb={["0rem", null, null, "0.9rem"]}>
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              p="0.8rem"
              onClick={() => setModalOpened(true)}
              css={{cursor: "pointer"}}
            >
              <Img fluid={data.trainingImage.childImageSharp.fluid}></Img>
            </Box>
          </Box>

        </Width>
        {modalOpened &&
            <OptinModal navigateTo="/free-course-optin" CTA="Get the Course" closeModal={()=>setModalOpened(false)}></OptinModal>
        }

      </Box>
    </Layout>
  )
}


export const optInQuery = graphql`
  query {
    trainingImage: file(relativePath: { eq: "training.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`